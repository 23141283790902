<script setup lang="ts">
const { getArticles, articles } = useTabFilters()
const { $gtm } = useNuxtApp()

const isLoading = ref(false)
const subscribeEmail = ref('')

async function sendFeedback() {
  isLoading.value = true

  await useSubscribe(subscribeEmail.value)
  $gtm.sendAnalytics(
    'newsletter_main_subscribe_submit',
    'submit',
    'Newsletter',
    'Подписаться',
  )

  isLoading.value = false
  subscribeEmail.value = ''
}

await getArticles()
const threeArticles = computed(() => articles.value.slice(0, 3))
</script>

<template>
  <div
    class="max-w-[1012px] my-12 px-4 md:my-24 gap-14 flex flex-row text-black w-full mx-auto"
  >
    <div class="md:w-1/2 gap-10">
      <h2 class="mb-5 md:mb-0 lg:text-h2 text-m-h2">
        Читайте полезные статьи о картах, отзывах и клиентах
      </h2>
      <RSkeleton v-if="!articles" class="bg-gray-200 w-full h-32" />
      <div v-else class="md:hidden flex flex-col gap-5">
        <NuxtLink
          :to="`/${threeArticles[0].full_slug}`"
        >
          <NuxtImg
            
            placeholder
            loading="lazy"
            :src="`${threeArticles[0].content.article_img.filename}/m/filters:format(webp)`"
            class="rounded-xl"
            :alt="`${threeArticles[0].name} image`"
          />
          <p
            class="mt-2 text-m-primary-large"
          >
            {{ threeArticles[0].name }}
          </p>
        </NuxtLink>
        <div class="grid grid-cols-2 gap-5">
          <NuxtLink
            v-for="(article, idx) in threeArticles.slice(1, 3)"
            :key="idx"
            :to="`/${article.full_slug}`"
          >
            <NuxtImg
              
              placeholder
              loading="lazy"
              :src="`${article.content.article_img.filename}/m/filters:format(webp)`"
              class="rounded-xl"
              :alt="`${threeArticles[0].name} image`"
            />
            <p
              class="mt-2 text-m-primary-small"
            >
              {{ article.name }}
            </p>
          </NuxtLink>
        </div>
      </div>
      <NuxtLink to="/blog" class="md:hidden">
        <div class="mt-4 text-blue-500 font-sans flex gap-2 text-m-primary-large">
          Перейти в блог <p class="opacity-50">
            →
          </p>
        </div>
      </NuxtLink>
      <p class="text-m-primary-bolder md:text-primary mt-8">
        Чтобы сэкономить вам время, пришлём новые статьи на почту. Пишем раз в
        две недели.
      </p>
      <form
        class="flex flex-col md:flex-row items-start md:items-center gap-5 mt-5 md:mt-10 mb-3"
        @submit.prevent="sendFeedback"
      >
        <input
          v-model="subscribeEmail"
          :disabled="isLoading"
          required
          class="w-full max-w-[461px] text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="Эл.почта"
          type="email"
        >
        <RButton :is-loading="isLoading" type="submit" class="md:block md:w-auto text-black">
          Подписаться
        </RButton>
      </form>
      <div class="flex flex-col">
        <p class="text-primary-small">
          Нажимая кнопку «подписаться», вы даёте
        </p>
        <NuxtLink
          class="text-primary-small text-secondary-500 underline decoration-secondary-300 underline-offset-4 hover:text-blue-500 duration-150"
          to="https://rang.ai/privacy"
          target="_blank"
        >
          согласие на обработку персональных данных
        </NuxtLink>
      </div>
    </div>
    <div class="relative flex-auto hidden md:block">
      <NuxtLink
        v-for="(article, idx) in threeArticles"
        :key="idx"
        :to="`/${article.full_slug}`"
        class="absolute flex flex-col gap-2 hover:scale-105 duration-150"
        :class="{
          'top-2 right-0 w-6/12 h-3/5 rotate-6': idx === 0,
          'top-2 left-0 w-5/12 h-2/6 -rotate-6': idx === 1,
          'bottom-10 left-[25%] w-5/12 h-[25%] rotate-12': idx === 2,
        }"
      >
        <NuxtImg
          placeholder
          
          loading="lazy"
          :src="`${article.content.article_img.filename}/m/filters:format(webp)`"
          class="rounded-xl w-full min-h-24 object-cover"
          :alt="`Review article image: ${article.content.title}`"
        />
        <div
          class="font-semibold"
          :class="{
            'text-[20px]': idx === 0,
            'text-[16px]': idx === 1,
            'text-[13px]': idx === 2,
          }"
          v-html="article.content.title"
        />
      </NuxtLink>
    </div>
  </div>
</template>
