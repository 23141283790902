<script setup lang="ts">
import { showModal as showAuditModal } from '~/store/modal/AuditModal'
import { showModal as showFreeModal } from '~/store/modal/FreeModal'

const {
  public: { NUXT_PUBLIC_IS_PRODUCTION },
} = useRuntimeConfig()
const router = useRouter()
const { $gtm } = useNuxtApp()

const isFree = computed(() => router.currentRoute.value.path === '/free')
const isRating = computed(() => router.currentRoute.value.path.split('/')[1] === 'rating')

function showModal() {
  isFree.value ? showFreeModal('') : showAuditModal('')

  $gtm.sendAnalytics(
    'audit_header_form_open',
    'click',
    'Audit',
    'Получить аудит',
  )
}
</script>

<template>
  <header class="max-w-[1290px] w-full mx-auto px-4 lg:px-8">
    <div
      class="flex lg:flex gap-0 lg:justify-between lg:items-center justify-between h-[116px] flex-wrap lg:flex-nowrap sm:h-[96px]"
    >
      <div class="w-full flex items-center justify-between lg:justify-normal">
        <NuxtLink
          to="/"
          aria-label="Navigate to main page"
          class="main_logo flex items-center justify-start mb-1"
        >
          <svg class="text-black h-[25px] w-[75px] mb-0 md:mb-2">
            <use xlink:href="~/assets/i/sprite/sprite.svg#logo" />
          </svg>
        </NuxtLink>
        <!-- Desktop -->
        <div
          class="hidden lg:flex ml-6 md:ml-12 lg:h-[26px] h-auto md:text-primary text-m-primary w-full lg:w-auto whitespace-nowrap flex-wrap lg:flex-nowrap lg:order-2 order-last gap-x-4 xl:gap-x-10"
        >
          <NuxtLink
            :class="{ 'text-secondary-500': isRating }"
            class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
            to="/rating/601/4/moskva/mnogoprofilnye-kliniki"
          >
            Рейтинг компаний
          </NuxtLink>
          <NuxtLink
            active-class="text-secondary-500"
            class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
            to="/faq"
          >
            Вопросы и ответы
          </NuxtLink>
          <NuxtLink
            v-if="!NUXT_PUBLIC_IS_PRODUCTION"
            active-class="text-secondary-500"
            class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
            to="/features"
          >
            Функции
          </NuxtLink>
          <NuxtLink
            active-class="text-secondary-500"
            class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
            to="/reviews"
          >
            Отзывы
          </NuxtLink>
          <NuxtLink
            active-class="text-secondary-500"
            class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
            to="/blog"
          >
            Блог
          </NuxtLink>
        </div>
        <!-- Mobile -->
        <div
          class="flex lg:hidden gap-3 lg:gap-x-5 col-span-1 lg:col-span-1 order-2 lg:order-last"
        >
          <div
            class="tracking-wide flex items-center gap-2 group"
            @click="showModal"
          >
            <NuxtImg
              v-if="!isFree"
              src="assets/i/userpic.jpg"
              class="w-6 h-6 md:w-8 md:h-8 rounded-full bg-amber-400 whitespace-nowrap"
              alt="user picture"
            />
            <p
              class="ml-2 min-w-32 text-blue-500 md:text-primary text-m-primary cursor-pointer group-hover:text-blue-300 duration-150"
            >
              {{ isFree ? "Попробовать бесплатно" : "Получить аудит" }}
            </p>
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div
        class="hidden lg:flex gap-3 lg:gap-x-5 col-span-1 lg:col-span-1 order-2 lg:order-last"
      >
        <div
          class="tracking-wide flex items-center gap-2 group"
          @click="showModal"
        >
          <NuxtImg
            
            src="assets/i/userpic.jpg"
            class="w-6 h-6 md:w-8 md:h-8 rounded-full bg-amber-400 whitespace-nowrap"
            alt="user picture"
          />
          <p
            :class="isFree ? 'min-w-20' : 'min-w-44'"
            class="text-blue-500 md:text-primary text-m-primary cursor-pointer group-hover:text-blue-300 duration-150"
          >
            {{ isFree ? "Попробовать бесплатно" : "Получить аудит" }}
          </p>
        </div>
        <div class="flex items-center">
          <NuxtLink
            target="_blank"
            to="https://app.rangai.ru/"
            type="button"
            class="md:text-btn-text text-m-primary text-blue-500 px-4 py-1 md:px-5 md:py-3 border border-blue-500 border-opacity-30 hover:border-opacity-50 rounded-md duration-150"
            @click="$gtm.sendAnalytics('signin', 'click', 'App', 'Вход')"
          >
            Вход
          </NuxtLink>
        </div>
      </div>
      <!-- Mobile -->
      <div
        class="h-auto text-m-primary w-full whitespace-nowrap flex-wrap order-last flex gap-x-4 lg:hidden"
      >
        <NuxtLink
          :class="{ 'text-secondary-500': isRating }"
          class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
          to="/rating/601/4/moskva/mnogoprofilnye-kliniki"
        >
          Рейтинг компаний
        </NuxtLink>
        <NuxtLink
          active-class="text-secondary-500"
          class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
          to="/faq"
        >
          Вопросы и ответы
        </NuxtLink>
        <NuxtLink
          active-class="text-secondary-500"
          class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
          to="/features"
        >
          Функции
        </NuxtLink>
        <NuxtLink
          active-class="text-secondary-500"
          class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
          to="/reviews"
        >
          Отзывы
        </NuxtLink>
        <NuxtLink
          active-class="text-secondary-500"
          class="text-blue-500 cursor-pointer hover:text-blue-300 duration-150"
          to="/blog"
        >
          Блог
        </NuxtLink>
      </div>
    </div>
  </header>
</template>
