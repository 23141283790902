const modalState = reactive({
  isOpen: false,
  website: '',
})

function showModal(website: string = '') {
  Object.assign(modalState, { isOpen: true, website })
}
const hideModal = () => modalState.isOpen = false

export {
  hideModal,
  modalState,
  showModal,
}
