<script setup lang="ts">
import { showModal } from '~/store/modal/AuditModal'

const { $gtm } = useNuxtApp()
const website = ref('')

function sendForm() {
  showModal(website.value)
  website.value = ''
  $gtm.sendAnalytics(
    'audit_blue_form_open',
    'click',
    'Audit',
  )
}
</script>

<template>
  <div id="audit" ref="target" class="z-40 max-w-[1012px] bg-blue-500 my-12 md:my-24 rounded-none md:rounded-xl gap-14 flex flex-row text-white px-4 py-10 md:pr-20 md:py-20 md:pl-20 lg:pr-0 w-full mx-auto ">
    <div>
      <h2 class="lg:text-h2 text-m-h2">
        Получить аудит карточек на картах
      </h2>

      <p class="lg:text-primary text-m-primary mt-5">
        Проведем бесплатный аудит ваших филиалов в картах совместно с аналитиком Ранга, определим слабые места и точки роста
      </p>
      <form class="flex flex-col md:flex-row items-center gap-5 mt-10" @submit.prevent="sendForm">
        <input
          v-model="website"
          required
          class="w-full max-w-[461px] text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          placeholder="Сайт"
          type="text"
          pattern=".+"
        >
        <RButton type="submit" class="hidden md:block md:w-auto text-black" font-styles="font-bold">
          Заказать
        </RButton>
        <RButton type="submit" class="w-full block md:hidden text-m-primary text-black" font-styles="!text-wrap font-bold">
          Получить аудит карточек на картах
        </RButton>
      </form>
    </div>
    <div class="hidden lg:block lg:relative md:w-2/3">
      <NuxtImg
        quality="70"
        class="lg:w-[400px] lg:h-[530px] absolute rounded-xl z-40 -top-[calc(100%)] lg:-top-[calc(50%+24px)]"
        src="/img_.png"
        alt="request content image"
        loading="lazy"
        width="306"
        height="543"
      />
    </div>
  </div>
</template>
